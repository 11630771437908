<template>
  <fragment>
    <CardTable
      :title="title"
      :subTitle="subTitle"
      :addFilter="false"
      :columns="table.columns"
      :rows="table.rows"
      :isLoading="table.isLoading"
      :pageSize="serverParams.pageSize"
      :pageNumber="serverParams.pageNumber"
      :totalPage="table.totalPage"
      :totalRecords="table.totalRecords"
      @onPerPageChange="onPerPageChange"
      @onPageChange="onPageChange"
    >
      <template #search>
        <div class="ml-3">
          <DatePicker
            :initialDates="[
              serverParams.dateRange.startDate,
              serverParams.dateRange.endDate,
            ]"
            :dateInput="dateInput"
            :switchButtonInitial="true"
            :showHelperButtons="true"
            language="id"
            @date-applied="changeDate"
          />
        </div>
      </template>
      <template #filter>
        <b-row class="p-3">
          <b-col xl="3">
            <treeselect
              class="mb-2"
              placeholder="Pilih Store"
              v-model="serverParams.storeId"
              :clearable="false"
              :options="options.store"
              @input="getStoreById"
            />
          </b-col>
          <b-col xl="3">
            <b-input
              class="mb-2"
              placeholder="Nomor Transaksi"
              v-model="serverParams.transactionNumber"
              @input="onFilter"
            />
          </b-col>
          <b-col xl="3">
            <treeselect
              class="mb-2"
              placeholder="Pilih Kategori"
              v-model="serverParams.expenseGroup"
              :options="options.expenseGroup"
              :normalizer="normalizer"
              @input="onFilter"
            />
          </b-col>
          <b-col xl="3">
            <treeselect
              class="mb-2"
              placeholder="Pilih Kasir"
              v-model="serverParams.cashierId"
              :options="options.cashier"
              @input="onFilter"
            />
          </b-col>
        </b-row>
      </template>
      <template #empty>
        <EmptyTable
          title="Belum ada data pengeluaran"
          :description="subTitle"
        />
      </template>
      <template #cell(transactionDate)="data">
        {{ dateFormat(data.value) }}
      </template>
      <template #cell(quantity)="data">
        {{ numberFormat(data.value) }}
      </template>
      <template #cell(amount)="data">
        {{ numberFormat(data.value) }}
      </template>
      <template #cell(grandTotal)="data">
        {{ numberFormat(data.value) }}
      </template>
    </CardTable>
  </fragment>
</template>

<script>
import { mapGetters } from "vuex";
import { pengeluaran as columns } from "@/core/datasource/column";
import { expenseGroup } from "@/core/datasource/options";
import {
  getStore,
  pageSize,
  dateFormat,
  numberFormat,
  normalizer,
  getDate,
  getCashier,
  toDate,
} from "@/core/utils";
import { SET_STORE } from "@/core/services/store/personalize.module.js";
import DatePicker from "vue-time-date-range-picker/dist/vdprDatePicker";

export default {
  components: {
    DatePicker,
  },
  data: () => ({
    title: "Pengeluaran",
    subTitle: "Menampilkan data pengeluaran per periode",
    searchText: "Cari nomor pengeluaran",
    serverParams: {
      pageNumber: 1,
      pageSize,
      dateRange: {
        startDate: toDate(getDate("YYYY/MM/DD 00:00:00")),
        endDate: toDate(getDate("YYYY/MM/DD 23:59:59")),
      },
      transactionNumber: null,
      expense: null,
      expenseGroup: null,
      minimumAmount: null,
      maximumAmount: null,
      storeId: null,
      cashierId: null,
      orderBy: "",
    },
    table: {
      isLoading: false,
      columns,
      rows: [],
      totalPage: 0,
      totalRecords: 0,
    },
    options: {
      expenseGroup,
      store: [],
      cashier: [],
    },
    dateInput: {
      inputClass: "form-control",
    },
  }),
  created() {
    const self = this;

    getStore().then((response) => {
      self.options.store = response.data.map((x) => ({
        id: x.id,
        label: x.storeName,
      }));
      if (self.options.store.length > 0) {
        if (self.store != null) {
          self.serverParams.storeId = self.store.id;
        } else {
          self.serverParams.storeId = self.options.store[0].id;
        }
      }
    });
  },
  computed: {
    ...mapGetters(["store"]),
  },
  methods: {
    dateFormat,
    numberFormat,
    normalizer,
    changeDate(from, to) {
      const self = this;
      self.serverParams.dateRange.startDate = from;
      self.serverParams.dateRange.endDate = to;
      self.getAll();
    },
    getStoreById(storeId) {
      const self = this;

      if (storeId != null) {
        self.$store
          .dispatch("apis/get", {
            url: `store/${storeId}`,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.$store.dispatch(SET_STORE, response.data);
            }
          })
          .finally(() => {
            getCashier(storeId).then((response) => {
              self.options.cashier = response.data.map((x) => ({
                id: x.id,
                label: x.fullName,
              }));
            });
            self.getAll();
          });
      }
    },
    getAll() {
      const self = this;

      let _serverParams = {
        pageNumber: self.serverParams.pageNumber,
        pageSize: self.serverParams.pageSize,
        transactionNumber: self.serverParams.transactionNumber,
        expense: self.serverParams.expense,
        expenseGroup: self.serverParams.expenseGroup,
        minimumAmount: self.serverParams.minimumAmount,
        maximumAmount: self.serverParams.maximumAmount,
        storeId: self.serverParams.storeId,
        cashierId: self.serverParams.cashierId,
        startDate: self.serverParams.dateRange.startDate,
        endDate: self.serverParams.dateRange.endDate,
      };
      self.table.isLoading = true;
      self.$store
        .dispatch("apis/get", {
          url: `/report/${self.serverParams.storeId}/expense`,
          params: _serverParams,
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });
          } else {
            self.table.rows = response.data.data;
            self.table.totalPage = response.data.totalPage;
            self.table.totalRecords = response.data.totalData;
          }
          self.table.isLoading = false;
        });
    },
    updateParams(newProps) {
      const self = this;
      self.serverParams = Object.assign({}, self.serverParams, newProps);
    },
    onPerPageChange(params) {
      const self = this;
      self.updateParams({ pageSize: params, pageNumber: 1 });
      self.getAll();
    },
    onPageChange(params) {
      const self = this;
      self.updateParams({ pageNumber: params });
      self.getAll();
    },
    onFilter() {
      const self = this;
      self.getAll();
    },
  },
};
</script>
